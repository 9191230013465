import { theme } from "@product/scmp-sdk";
import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { AdSlot } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { BaseLink } from "scmp-app/components/common/base-link";
import { AdContainer } from "scmp-app/components/section/section-post-magazine/styles";
import type { headerPostMagazineQuery$key } from "scmp-app/queries/__generated__/headerPostMagazineQuery.graphql";

import {
  Container,
  LogoContainer,
  LogoPostMagazine,
  LogoScmpSvg,
  StyledBaseLink,
  StyledEntityOnelineMenu,
  TagLine,
  TagLineWrapper,
} from "./styles";

export type Props = {
  className?: string;
  isMainSection?: boolean;
  reference: headerPostMagazineQuery$key;
};
export const PostMagazineHeader: FunctionComponent<Props> = ({
  className,
  isMainSection = false,
  reference: reference_,
}) => {
  const data = useFragment(
    graphql`
      fragment headerPostMagazineQuery on Query {
        postMagSection: section(filter: { entityId: "71" }) {
          urlAlias
          subSections {
            items {
              edges {
                ...entityOnelineMenuQueueItemsEdge
              }
            }
          }
        }
      }
    `,
    reference_,
  );

  const extraSlide = isMainSection ? null : (
    <StyledBaseLink pathname={data?.postMagSection?.urlAlias ?? "/"}>
      <span>HOME</span>
    </StyledBaseLink>
  );

  return (
    <Container className={className}>
      <LogoContainer>
        <BaseLink pathname="/">
          <LogoScmpSvg />
        </BaseLink>
        <BaseLink pathname="/postmag">
          <LogoPostMagazine />
        </BaseLink>
      </LogoContainer>
      <StyledEntityOnelineMenu
        extraSlide={extraSlide}
        reference={data.postMagSection.subSections?.items?.edges ?? []}
      />
      <TagLine>
        <TagLineWrapper>
          <AdContainer>
            <AdSlot
              adUnit="d_banner3"
              breakpoint={theme.breakpoints.up("homeDesktop")}
              sizes={[[300, 185], "fluid"]}
              zone="postmag/home"
            />
            <AdSlot
              adUnit="m_banner2"
              breakpoint={theme.breakpoints.down("homeDesktop")}
              sizes={[[116, 72], "fluid"]}
              zone="postmag/home"
            />
          </AdContainer>
        </TagLineWrapper>
      </TagLine>
    </Container>
  );
};

PostMagazineHeader.displayName = "PostMagazineHeader";
